

/**
 * @description 格式化时间
 * @param {String|Number} dateTime 需要格式化的时间戳
 * @param {String} fmt 格式化规则 yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合 默认yyyy-mm-dd
 * @returns {string} 返回格式化后的字符串
 */
export function timeFormat(dateTime = null, formatStr = 'yyyy-mm-dd') {
  let date
	// 若传入时间为假值，则取当前时间
  if (!dateTime) {
    date = new Date()
  }
  // 若为unix秒时间戳，则转为毫秒时间戳（逻辑有点奇怪，但不敢改，以保证历史兼容）
  else if (/^\d{10}$/.test(dateTime?.toString().trim())) {
    date = new Date(dateTime * 1000)
  }
  // 若用户传入字符串格式时间戳，new Date无法解析，需做兼容
  else if (typeof dateTime === 'string' && /^\d+$/.test(dateTime.trim())) {
    date = new Date(Number(dateTime))
  }
	// 处理平台性差异，在Safari/Webkit中，new Date仅支持/作为分割符的字符串时间
	// 处理 '2022-07-10 01:02:03'，跳过 '2022-07-10T01:02:03'
	else if (typeof dateTime === 'string' && dateTime.includes('-') && !dateTime.includes('T')) {
		date = new Date(dateTime.replace(/-/g, '/'))
	}
	// 其他都认为符合 RFC 2822 规范
	else {
		date = new Date(dateTime)
	}

	const timeSource = {
		'y': date.getFullYear().toString(), // 年
		'm': (date.getMonth() + 1).toString().padStart(2, '0'), // 月
		'd': date.getDate().toString().padStart(2, '0'), // 日
		'h': date.getHours().toString().padStart(2, '0'), // 时
		'M': date.getMinutes().toString().padStart(2, '0'), // 分
		's': date.getSeconds().toString().padStart(2, '0') // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	}

  for (const key in timeSource) {
    const [ret] = new RegExp(`${key}+`).exec(formatStr) || []
    if (ret) {
      // 年可能只需展示两位
      const beginIndex = key === 'y' && ret.length === 2 ? 2 : 0
      formatStr = formatStr.replace(ret, timeSource[key].slice(beginIndex))
    }
  }

  return formatStr
}


/**
 * 下载图片文件到本地
 * @param {Object} fileName 资源文件名称
 */
export function downloadImg(fileName) {
	let image = new Image();
	image.src = fileName;
	    // 解决跨域 Canvas 污染问题
	image.setAttribute("crossOrigin", "Anonymous"); // 支持跨域
	image.setAttribute('crossOrigin', 'anonymous');
	image.onload = function() {
			var canvas = document.createElement("canvas");
	        canvas.width = image.width;
	        canvas.height = image.height;
	        var context = canvas.getContext("2d");
	        context.drawImage(image, 0, 0, image.width, image.height);
	        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
	        var a = document.createElement("a"); // 生成一个a元素
	        var event = new MouseEvent("click"); // 创建一个单击事件
	        a.download = "photo"; // 设置图片名称
	        a.href = url; // 将生成的URL设置为a.href属性
	        a.dispatchEvent(event); // 触发a的单击事件
	}
}

/**
 * 获取url地址里面的参数
 * @param {Object} url 地址
 */
export function queryURLParams(url) {
    let pattern = /(\w+)=(\w+)/ig; //定义正则表达式
    let parames = {}; // 定义参数对象
    url.replace(pattern, ($, $1, $2) => {
      parames[$1] = $2;
    });
    return parames;
}

/**
 * 文件下载
 * @param {Object} url 下载地址
 */
export function fileDownload(url) {
	if (url == '' || url == undefined || url == null || !url) {
		 alert('url地址错误！');
		return;
	}
	window.open(url);
}

/**
 * 选择文件
 * @param {Object} file 选择的文件
 * @param {Object} fileId input标签的ID
 */
export function fileUpload(file, fileId) {
	try{
		let fileValue = document.getElementById(fileId).value;
		let files = file.target.files[0];
		let formData = new FormData();       // 创建form对象     
		formData.append('file', files);      // 通过append向form对象添加数据		
		return formData;
	}catch(e){
		return {error: e};
	}
}


<template>
    <div class="index-main">
		<el-container style="height: 100%;">
			<el-aside width="200px">
				<menu-bar></menu-bar>
			</el-aside>
			<el-container>
				<el-header height="60px" style="background-color: #fff;">
					<header-bar></header-bar>
				</el-header>
			    <el-main style="padding: 0;background: #F1F4F7;">
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
    </div>
  </template>
  
  <script>
	import menuBar from "@/components/menu-bar.vue";
	import headerBar from "@/components/header-bar.vue";
  export default {
	components: {
		menuBar, headerBar
	},
    data(){
      return {
			
      }
    },
	mounted() {
		this.initData();
	},
    methods: {
		initData() {
			
		}
		
    }
    
  }
  </script>
  
  <style scoped lang="scss">
	  .index-main{height: 100vh;}
  
  </style>
  
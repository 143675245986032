<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import * as auth from "@/utils/auth.js"
	
export default {
  name: 'App',
  components: {
	  
  },
  created() {
  	
  }
}
</script>

<style>
html{min-height: 100%;}
body{margin: 0;min-height: 100vh;}
#nprogress .bar {background: blue !important}
.touch-hover{cursor: pointer;}
	.flex{
	  display: flex;
	}
	.flex-row-center{
	  justify-content: center;
	}
	.flex-row-between{
	  justify-content: space-between;
	}
	.flex-row-around{
	  justify-content: space-around;
	}
	.flex-row-start{
	  justify-content: flex-start;
	}
	.flex-row-end{
	  justify-content: flex-end;
	}
	.flex-col{
	  flex-direction: column;
	}
	.flex-col-center{
	  align-items: center;
	}
	.flex-col-start{
	  align-items: flex-start;
	}
	.flex-col-end{
		align-items: flex-end;
	}
	.flex-wrap{
	  flex-wrap: wrap;
	}
	.flex-no-wrap{
		flex-wrap: nowrap;
	}


</style>



/***************************** 一企一档所有接口 **************************************/


// 导入axios实例
import httpRequest from '@/request/index'



// 获取企业列表
export function companyLists(param) {
    return httpRequest({
		url: 'admin/company/lists',
		method: 'POST',
		data: param,
	})
}


// 获取企业画像
export function companyInfo(param) {
    return httpRequest({
		url: 'admin/company/info',
		method: 'POST',
		data: param,
	})
}


// 获取企业雷达图区域
export function companyPortrayal(param) {
    return httpRequest({
		url: 'admin/company/portrayal',
		method: 'POST',
		data: param,
	})
}


// 获取问诊题目
export function companyProfile(param) {
    return httpRequest({
		url: 'admin/company/profile',
		method: 'POST',
		data: param,
	})
}

// 获取问诊报告列表
export function companyDiagnose(param) {
    return httpRequest({
		url: 'admin/company/diagnose',
		method: 'POST',
		data: param,
	})
}

//保存解决方法和与解决方案
export function saveMethod(param) {
   return httpRequest({
      url: 'admin/company/save',
      method: 'POST',
      data: param,
   })
}
//获取网格列表
export function Grids(param) {
	return httpRequest({
		url: 'admin/company/grids',
		method: 'POST',
		data: param,
	})
}

//分类列表
export function cate_list(param){
	return httpRequest({
		url:'admin/product/cate_list',
		method:'GET',
		data:param,
	})
}

//获取解决方案与产品推荐
export function Full(param) {
	return httpRequest({
		url: 'admin/company/full',
		method: 'POST',
		data: param,
	})
}

//上传附件解决方案
export function company_nalysis(param) {
	return httpRequest({
		url: 'admin/company/analysis',
		method: 'POST',
		data: param,
	})
}


//邀请问诊
export function company_invite(param) {
	return httpRequest({
		url: 'admin/company/invite',
		method: 'POST',
		data: param,
	})
}

//获取产品
export function product_list(param){
   return httpRequest({
      url:'admin/product/product_list',
      method:'GET',
	  params: param
   })
}


//企业答卷
export function questionnaire_contact_list(param){
    return httpRequest({
        url:'admin/question/questionnaire_contact_list',
        method:'GET',
        params: param
    })
}

//答题详情
export function questionnaire_contact_detail(param){
    return httpRequest({
        url:'admin/question/questionnaire_contact_detail',
        method:'GET',
        params: param
    })
}

//下载答卷
export function questionnaire_download_question(param){
    return httpRequest({
        url:'admin/question/questionnaire_download_question',
        method:'GET',
        params: param
    })
}

//获取问卷列表
export function companyQuestionnaire(param){
    return httpRequest({
        url:'admin/company/questionnaire',
        method:'POST',
        data: param
    })
}

//获取问卷调查题目
export function companyQuestions(param){
    return httpRequest({
        url:'admin/company/questions',
        method:'POST',
        data: param
    })
}

//获取诊断人列表
export function diagnosesOption(param){
    return httpRequest({
        url:'admin/company/diagnoses',
        method:'POST',
        data: param
    })
}

//获取经济类型
export function companyGetEconomics(param){
    return httpRequest({
        url:'admin/company/getEconomics',
        method:'POST',
        data: param
    })
}

//获取企业类型
export function getCompanyType(param){
    return httpRequest({
        url:'admin/company/getCompanyType',
        method:'POST',
        data: param
    })
}

//获取机构类型
export function getInstitutional(param){
    return httpRequest({
        url:'admin/company/getInstitutional',
        method:'POST',
        data: param
    })
}
//获取商企经理
export function getBusinessManage(param){
    return httpRequest({
        url:'admin/company/getBusinessManage',
        method:'POST',
        data: param
    })
}

//修改企业基本信息
export function saveCompanyInfo(param){
    return httpRequest({
        url:'admin/company/saveCompanyInfo',
        method:'POST',
        data: param
    })
}

// 企业认证审核-获取企业待审核列表
export function getEntAuditList(param) {
    return httpRequest({
        url: 'admin/company/audit_lists',
        method: 'get',
        params: param
    })
}

// 企业认证审核-获取企业已驳回列表
export function getEntRejectList(param) {
    return httpRequest({
        url: 'admin/company/reject_lists',
        method: 'get',
        params: param
    })
}

// 企业认证审核-企业认证详情
export function getEntDetail(id) {
    return httpRequest({
        url: 'admin/company/authentication_info',
        method: 'get',
        params: {
            id: id
        }
    })
}

// 企业认证审核-认证审核
export function auditEnt(param) {
    return httpRequest({
        url: 'admin/company/authentication_save',
        method: 'post',
        data: param
    })
}






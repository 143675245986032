// 导入axios实例
import httpRequest from '@/request/index'

// 获取角色列表
export function organizeList(param) {
    return httpRequest({
        url: 'admin/system/organize_list',
        method: 'GET',
        data: param,
    })
}

// 根据id获取角色信息
export function getOrganize(id) {
    return httpRequest({
        url: 'admin/system/organize_info/' + id,
        method: 'GET'
    })
}

// 根据id删除数据角色
export function delOrganize(data) {
    return httpRequest({
        url: 'admin/system/organize_del',
        method: 'put',
        data
    })
}

// 修改角色信息
export function editOrganize(param) {
    return httpRequest({
        url: 'admin/system/organize_save',
        method: 'post',
        data: param,
    })
}

//  权限菜单树列表
export function getOrganizeTreeList() {
    return httpRequest({
        url: 'admin/system/organize_tree_list',
        method: 'get'
    })
}

//  权限菜单树列表
export function getOrganizeOption(type) {
    return httpRequest({
        url: 'admin/system/organize_option_list/' + type,
        method: 'get'
    })
}

// 保存园区总览
export function saveDataPark(param) {
    return httpRequest({
        url: 'admin/system/data_reporting/data_park_save',
        method: 'post',
        data: param,
    })
}

//填报详情
export function dataReportingDetail(param) {
    return httpRequest({
        url: 'admin/system/data_reporting/detail',
        method: 'GET',
        params: param
    })
}

//填报机构选项
export function dataReportingList(param) {
    return httpRequest({
        url: 'admin/system/data_reporting/organize_list',
        method: 'GET',
        params: param
    })
}

// 填报复制添加/编辑
export function dataReportingCopy(param) {
    return httpRequest({
        url: 'admin/system/data_reporting/save',
        method: 'get',
        params: param,
    })
}
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import * as api from './request/api'
import { resetForm, parseTime, selectDictLabel, selectDictLabels, formatEchoData} from '@/utils/lzzcloud.js'
import { fileDownload } from '@/utils/index.js'
import ElementUI, { MessageBox } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Cookies from 'js-cookie';
import '@/styles/index.scss';
import * as echarts from 'echarts'
// 引入富文本组件
import QuillEditor from 'vue-quill-editor'
// 引入富文本组件样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import htmlToPdf from '@/utils/htmlToPdf'
Vue.use(htmlToPdf)

Vue.use(ElementUI)
Vue.use(QuillEditor)
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.prototype.$cookies = Cookies;
Vue.prototype.resetForm = resetForm;
Vue.prototype.parseTime = parseTime;
Vue.prototype.fileDownload = fileDownload;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectDictLabels = selectDictLabels;
Vue.prototype.formatEchoData = formatEchoData;
const app = new Vue({
    render: h => h(App),
    store,
    router
})
app.$mount('#app');
<template>
	<div class="header-main flex flex-col-center flex-row-between">
		<div class="title-text">
			<el-breadcrumb separator="/">
			  <el-breadcrumb-item v-for="(item, index) in breadList" :key="index" @click.prevent="handleLink(item)">{{item.meta.title}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="name-content flex flex-col-center">
			<div class="">
				您好，
        <el-popover
          placement="bottom"
          width="74"
          trigger="hover"
          popper-class="userPopper"
          >
          <ul>
            <li :class="{active: $route.path === '/userPanel'}" @click="handleLink({path:'/userPanel'})"><span>个人中心</span></li>
            <li @click="exitClick"><span class="el-icon-close-circle">退出登录</span></li>
          </ul>
        <span slot="reference" class="name-text">{{userInfo.full_name?userInfo.full_name:'--'}}</span>
      </el-popover>
			</div>
		</div>
	</div>
</template>

<script>
	import * as auth from "@/utils/auth.js"
	export default{
		name: 'header-bar',
		props: {
			
		},
		data(){
			return{
				breadList: []
			}
		},
    watch: {
      $route(route) {
        // if you go to the redirect page, do not update the breadcrumbs
        if (route.path.startsWith('/redirect/')) {
          return
        }
        this.getBreadcrumb()
      }
    },
    created() {
      this.getBreadcrumb()
    },
		methods:{
			//退出登录
			exitClick() {
				this.$confirm('确定退出此账号?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() => {
					auth.removeToken();
					this.$router.push({path: '/login', query: {}});
				}).catch(() => {});
			},
			getBreadcrumb() {
				// let matched = this.$route.matched;
				// if (this.isHome(matched[0])) {
					// matched.splice(0,1);
				// }
				let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
				const first = matched[0]
				if (!this.isDashboard(first)) {
				  // matched = [{ path: '/index', meta: { title: '首页' }}].concat(matched)
				}

				this.breadList = matched.filter(item => item.meta && item.meta.title)
				// this.breadList = matched;
			},
      isDashboard(route) {
        const name = route && route.name
        if (!name) {
          return false
        }
        return name.trim() === 'Index'
      },
			isHome(route) {
				return route.name==='index';
			},
      handleLink(item) {
        const { redirect, path } = item
        if (redirect) {
          this.$router.push(redirect)
          return
        }
        this.$router.push(path)
      }
			
		},
		computed: {
			userInfo() {
				return this.$store.state.userInfo;
			}
		},
		// watch: {
		// 	$route() {
		// 		this.getBreadcrumb();
		// 	}
		// }
	}
</script>

<style lang="scss" scoped>
	.header-main{padding: 0 10px;height: 100%;
		.title-text{font-size: 14px;color: #242D33;}
		.name-content{color: #242D33;font-size: 14px;
			.name-text{color: #3984F5;}
			.exit-btn{color: #6C7880;margin-left: 10px;background: none;font-size: 14px;}
			.exit-btn:hover{text-decoration: underline;}
		}

	}
	
</style>
<style lang="scss">
.userPopper {
  min-width: 84px!important;
  padding: 4px 0!important;

  ul {
    li {
      text-align: center;
      line-height: 34px;
      padding: 0 10px;
      color: #6C7880;
      cursor: pointer;
      font-size: 12px;
      transition: all 0.3s ease;
      border-bottom: 1px solid #EEEFF2;

      &:last-child {
        border-bottom: none;
      }

      &.active {
        color: #3984F5;
      }

      &:hover {
        color: #3984F5;
      }
    }
  }
}
</style>
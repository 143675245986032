// 导入axios实例
import httpRequest from '@/request/index'

// 获取员工列表
export function userList(param) {
    return httpRequest({
        url: 'admin/system/admin_list',
        method: 'GET',
        data: param,
    })
}
// 根据id获取员工信息
export function getUser(id) {
    return httpRequest({
        url: 'admin/system/admin_info/' + id,
        method: 'GET'
    })
}
// 根据id删除数据员工
export function delUser(param) {
    return httpRequest({
        url: 'admin/system/admin_del',
        method: 'put',
        data: param,
    })
}

// 根据id修改员工状态
export function editUserStatus(param) {
    return httpRequest({
        url: 'admin/system/admin_status',
        method: 'post',
        data: param,
    })
}

// 修改员工信息
export function editUser(param) {
    return httpRequest({
        url: 'admin/system/admin_save',
        method: 'post',
        data: param,
    })
}

// 重置员工密码信息
export function resetPwd(param) {
    return httpRequest({
        url: 'admin/system/admin_reset_password',
        method: 'post',
        data: param,
    })
}

// 修改密码
export function changePwd(param) {
    return httpRequest({
        url: 'admin/system/admin_change_password',
        method: 'post',
        data: param,
    })
}

// 下载员工信息
export function downloadUser(param) {
    return httpRequest({
        url: 'admin/export/admin_list',
        method: 'get',
        data: param,
    })
}

// 下载员工信息
export function uploadUser(param) {
    return httpRequest({
        url: 'admin/system/admin_import',
        method: 'post',
        data: param,
    })
}

// 员工选项列表 角色ID：0=全部 1=商企经理
export function optionUserList(role_id) {
    return httpRequest({
        url: 'admin/system/admin_option_list/' + role_id,
        method: 'get'
    })
}

import axios from 'axios';
import * as auth from "@/utils/auth.js"
import router from '../router'
import {Message} from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'
import { tansParams } from "@/utils/lzzcloud.js";
import cache from '@/plugins/cache'


// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'


// 创建一个 axios 实例
const service = axios.create({
        baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/api', // 所有的请求地址前缀部分
        timeout: 60000 ,// 请求超时时间毫秒
		headers: {
			'From-type': 'pc',
			'Authorization': auth.getToken()?auth.getToken():''
		}
    })
// 添加请求拦截器
service.interceptors.request.use(
    function(config) {
		// 在发送请求之前做些什么
        // console.log('请求之前数据：');
        // console.log(config)
		// config.headers['Content-Type'] = config.ContentType? config.ContentType:'application/json;charset=UTF-8';
		if (config.type && config.type === 'file') {
			config.headers['Content-Type'] = 'multipart/form-data';
		}

		// 是否需要设置 token
		const isToken = (config.headers || {}).isToken === false
		// 是否需要防止数据重复提交
		const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
		if (getToken() && !isToken) {
			config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
		}
		// get请求映射data参数
		if (config.method === 'get' && config.data) {
			let url = config.url + '?' + tansParams(config.data);
			url = url.slice(0, -1);
			config.data = {};
			config.url = url;
		}
		if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
			let data = null;
			if(typeof config.data === 'object') {
				if (config.type && config.type === 'file') {
					data = config.data
					console.log(1)
				} else {
					data = JSON.stringify(config.data)
					console.log(2)
				}
			} else {
				data = config.data
				console.log(3)
			}

			const requestObj = {
				url: config.url,
				data: data,
				time: new Date().getTime()
			}
			const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
			const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
			if (requestSize >= limitSize) {
				console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
				return config;
			}
			const sessionObj = cache.session.getJSON('sessionObj')
			if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
				cache.session.setJSON('sessionObj', requestObj)
			} else {
				const s_url = sessionObj.url;                  // 请求地址
				const s_data = sessionObj.data;                // 请求数据
				const s_time = sessionObj.time;                // 请求时间
				const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
				if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
					const message = '数据正在处理，请勿重复提交';
					console.warn(`[${s_url}]: ` + message)
					return Promise.reject(new Error(message))
				} else {
					cache.session.setJSON('sessionObj', requestObj)
				}
			}
		}
		return config
    },
    function(error) {
        // 对请求错误做些什么
        console.log(error)
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    function(response) {
        console.log(response)
            // 2xx 范围内的状态码都会触发该函数。
            // 对响应数据做点什么
            // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data
        // 这个状态码是和后端约定的
        const code = dataAxios.status;
		if (code===200) {
			return dataAxios
		} else if (code===40001) {
			Message({type: 'error', message: '登录已过期，请重新登录'});
			removeToken();
			router.push({path: '/login'});
			return;
		} else if (code===40002) {
			Message({type: 'error', message: '账号被禁用'});
		} else if (code===40004) {
			Message({type: 'error', message: '账号不存在'});
		} else {
			Message({type: 'error', message: dataAxios.msg});
		}
		return Promise.reject(dataAxios);
    },
    function(error) {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        console.log(error)
		Message({type: 'error', message: error.msg});
        return Promise.reject(error)
    }
)

export default service
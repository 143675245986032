// 导入axios实例
import httpRequest from '@/request/index'

// 获取基础设置信息
export function getBasics() {
    return httpRequest({
        url: 'admin/system/config_info',
        method: 'GET'
    })
}

// 编辑字典项信息
export function editBasics(param) {
    return httpRequest({
        url: 'admin/system/config_set',
        method: 'POST',
        data: param
    })
}


// 获取评测问卷选项列表
export function getQuestionnaireOptions() {
    return httpRequest({
        url: 'admin/question/questionnaire_eval_option_list',
        method: 'get'
    })
}
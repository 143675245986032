// 导入axios实例
import httpRequest from '@/request/index'

//商机基础信息
export function business_type_info(param){
    return httpRequest({
        url:'admin/business/business_type_info',
        method:'GET',
        params: param
    })
}

//商机列表
export function business_list(param){
    return httpRequest({
        url:'admin/business/business_list',
        method:'GET',
        params: param
    })
}

//添加/编辑商机
export function business_save(param) {
    return httpRequest({
        url: 'admin/business/save',
        method: 'POST',
        data: param,
    })
}

//获取地区信息
export function area_child_list(pid) {
    return httpRequest({
        url: `admin/area_child_list/${pid}`, // 将 ":pid" 替换为 "${pid}"
        method: 'GET',
        // 注意这里不再使用 params，而是将 pid 直接拼接到 url 中作为路径参数
    });
}

//获取全部地区
export function area_tree_list(param){
    return httpRequest({
        url:'admin/area_tree_list',
        method:'GET',
        params: param
    })
}

//商机详情
export function business_info(param){
    return httpRequest({
        url:'admin/business/info',
        method:'GET',
        params: param
    })
}

//获取拜访列表
export function company_company_list(param){
    return httpRequest({
        url:'admin/company/company_list',
        method:'GET',
        params: param
    })
}


//增加跟进记录
export function business_add_follow(param) {
    return httpRequest({
        url: 'admin/business/business_add_follow',
        method: 'POST',
        data: param,
    })
}

//根据地区id获取网格
export function business_grid_info(param){
    return httpRequest({
        url:`admin/business/business_grid_info/${param}`,
        method:'GET'
    })
}

//网格id获取企业
export function business_company_info(param){
    return httpRequest({
        url:`admin/business/business_company_info/${param}`,
        method:'GET'
    })
}

//修改立项状态
export function business_update_status(param) {
    return httpRequest({
        url: 'admin/business/business_update_status',
        method: 'POST',
        data: param,
    })
}

//企业拜访详情
export function company_company_detail(param){
    return httpRequest({
        url:`admin/company/company_detail/${param}`,
        method:'GET'
    })
}

//根据网格获取商企经理
export function business_manager_info(param){
    return httpRequest({
        url:`admin/business/business_manager_info/${param}`,
        method:'GET'
    })
}
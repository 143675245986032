// 导入axios实例
import httpRequest from '@/request/index'

/*分页列表*/
export function log_list(param) {
    return httpRequest({
        url: 'admin/system/log_list',
        method: 'GET',
        params: param,
    })
}

/*模块选项列表*/
export function menu_name_list(param) {
    return httpRequest({
        url: 'admin/system/menu_name_list',
        method: 'GET',
        data: param,
    })
}

/*导出日志*/
export function system_log_list(param) {
    return httpRequest({
        url: 'admin/export/system_log_list',
        method: 'GET',
        data: param,
    })
}
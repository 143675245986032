import Vue from "vue"
import Vuex from 'vuex'
import Cookies from "js-cookie"
import * as auth from "@/utils/auth.js"
import {getQuestionList, setQuestionList} from "../utils/auth";
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
		token: '',//用户登录token
		userInfo: auth.getUserInfo(),//用户信息
        reouterPath: Cookies.get('router_path'),//路由路径
		questionStatu: 0,//问卷状态,0编辑问卷，1发送名单，2发送问卷
		questionTitleData: null,
		qusestionList:auth.getQuestionList(),
		editStatus: 0,//编辑状态，0：未编辑，1：编辑中，2：已保存
    },
	getters: {
		isItAnAdministrator: state => state.userInfo && (state.userInfo.role_id === 1 || state.userInfo.admin_id === 1),
	},
    mutations: {
		SET_TOKEN(state, data) {
			auth.setToken(data);
			state.token = data;
		},
		SET_USER_INFO(state, data) {
			auth.setUserInfo(data);
			state.userInfo = data;
		},
        SET_ROUTERPATH(state, data) {
			Cookies.set('router_path', data);
			state.reouterPath = Cookies.get('router_path');
		},
		SET_QUESTION_STATU(state, data) {
			state.questionStatu = data;
		},
		SET_QUESTION_TITLE_DATA(state, data) {
			state.questionTitleData = data;
		},
		SET_QUESTION_LIST(state,data){
			auth.setQuestionList(data)
			state.qusestionList = data
		},
		SET_EDIT_STATUS(state, data) {
			state.editStatus = data;
		},
		
    },
    actions: {
       SET_EDIT_STATUS({commit}, data) {
			commit('SET_EDIT_STATUS', data);
		},
		GET_EDIT_STATUS() {
			return this.state.editStatus;
		},
	   
		
    }
})

export default store
<template>
  <div class="menu-bar-main">
    <img class="log-img" src="@/assets/menu/logo@2x.png" alt=""/>
    <el-menu
        text-color="#242D33"
        active-text-color="#3984F5"
        unique-opened
        router
        :default-active="menuIndex"
        class="el-menu-vertical-demo"
        @select="handleSelect">
			<span v-for="(item, index) in menuList" :key="index">
				<el-submenu :index="index+''" v-if="item.isShow" >
					<template slot="title">
						<img class="btn-img" :src="item.log" alt=""/>
						<span class="title-text">{{ item.title }}</span>
					</template>
					<el-menu-item-group>
						<span v-for="(items, indexs) in item.child" :key="items.id">
							<el-menu-item v-if="items.isShow" :index="items.path" :route="{path: items.path, query: items.params}">
								<img class="btn-img" :src="menuIndex==items.path?items.log_two: items.log" alt=""/>
								<span>{{ items.title }}</span>
							</el-menu-item>						
						</span>
					</el-menu-item-group>
					</el-submenu>
			</span>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuIndex: '',//菜单下标
      menuList: [
        {
          log: require('@/assets/menu/one_statistics_icon@2x.png'), title: '数据看板', isShow: false,
          child: [
            {
              isShow: true,
              log: require('@/assets/menu/two_Enterprise_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_Enterprise_blue_icon@2x.png'),
              title: '企业数据看板',
              path: '/dataBoard',
			  params: {url: 'http://180.130.116.166:9090/link/6rPZC2gQ'}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_product_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_product_blue_icon@2x.png'),
              title: '产品数据看板',
              path: '/productDataBoard',
			  params: {url: 'http://180.130.116.166:9090/link/ZVY18NIu'}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_grid_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_grid_blue_icon@2x.png'),
              title: '网格数据看板',
              path: '/gridDataBoard',
			  params: {url: 'http://180.130.116.166:9090/link/oEEQtTKN'}
            },
          ]
        },
        {
          log: require('@/assets/menu/one_diagnosis_icon@2x.png'), title: '企业问诊', isShow: false,
          child: [
            {
              isShow: true,
              log: require('@/assets/menu/two_wenjuan_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_wenjuan_blue_icon@2x.png'),
              title: '调研问卷',
              path: '/questionnaire',
			  params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_answer_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_answer_blue_icon@2x.png'),
              title: '企业答卷',
              path: '/answerSheet',
			  params: {}
            },
        //     {
        //       isShow: true,
        //       log: require('@/assets/menu/two_statistics_grey_icon@2x.png'),
        //       log_two: require('@/assets/menu/two_statistics_blue_icon@2x.png'),
        //       title: '统计分析',
        //       path: '/statistics',
			  // params: {}
        //     }, // 按需求暂时隐藏
            {
              isShow: true,
              log: require('@/assets/menu/two_problem_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_problem_blue_icon@2x.png'),
              title: '预设题库',
              path: '/questionBank',
			  params: {}
            },
          ]
        },
        {
          log: require('@/assets/menu/one_enterprise_icon@2x.png'), title: '一企一档', isShow: false,
          child: [
            {
              isShow: true,
              log: require('@/assets/menu/two_qiye_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_qiye_blue_icon@2x.png'),
              title: '企业列表',
              path: '/enterpriseList',
			  params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_report_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_report_blue_icon@2x.png'),
              title: '诊断报告',
              path: '/diagnosticReport',
			  params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/Auditing_grey_icon@2x.png'),
              log_two: require('@/assets/menu/Auditing_blue_icon@2x.png'),
              title: '企业认证审核',
              path: '/enterpriseCertification',
              params: {}
            }
          ]
        },
        {
          log: require('@/assets/menu/one_business_icon@2x.png'), title: '商机库', isShow: false,
          child: [
            {
              isShow: true,
              log: require('@/assets/menu/two_business_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_business_blue_icon@2x.png'),
              title: '商机线索',
              path: '/businessLeads',
			  params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_visit_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_visit_blue_icon@2x.png'),
              title: '企业拜访',
              path: '/businessVisit',
			  params: {}
            }
          ]
        },
        {
          log: require('@/assets/menu/one_product_icon@2x.png'), title: '产品库', isShow: false,
          child: [
            {
              isShow: true,
              log: require('@/assets/menu/two_cpk_icon.png'),
              log_two: require('@/assets/menu/two_cpk_icon_y.png'),
              title: '产品管理',
              path: '/productHome',
			  params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_classify_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_classify_blue_icon@2x.png'),
              title: '分类管理',
              path: '/classify',
			  params: {}
            }
          ]
        },
		{
			log: require('@/assets/menu/order_list_icon.png'), title: '订单管理', isShow: true,
			child: [
				{
				  isShow: true,
				  log: require('@/assets/menu/order_info_n_icon.png'),
				  log_two: require('@/assets/menu/order_info_y_icon.png'),
				  title: '产品订单管理',
				  path: '/orderManage',
				  params: {}
				},
				{
				  isShow: true,
				  log: require('@/assets/menu/order_invoice_n_icon.png'),
				  log_two: require('@/assets/menu/order_invoice_y_icon.png'),
				  title: '开票管理',
				  path: '/ticketManage',
				  params: {}
				}
			]
		},
        {
          log: require('@/assets/menu/one_setup_icon@2x.png'), title: '系统设置', isShow: false,
          child: [
            {
              isShow: true,
              log: require('@/assets/menu/two_structure_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_structure_blue_icon@2x.png'),
              title: '组织机构',
              path: '/organization',
			  params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_number_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_number_blue_icon@2x.png'),
              title: '员工账号',
              path: '/user',
			  params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_qxgl_icon.png'),
              log_two: require('@/assets/menu/two_qxgl_icon_y.png'),
              title: '权限管理',
              path: '/roles',
			  params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_comment_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_comment_blue_icon@2x.png'),
              title: '评语设置',
              path: '/commentSet',
			  params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_basis_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_basis_blue_icon@2x.png'),
              title: '基础设置',
              path: '/basics',
			  params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/journal_grey_icon@2x.png'),
              log_two: require('@/assets/menu/journal_blue_icon@2x.png'),
              title: '系统日志',
              path: '/logs',
              params: {}
            },
            {
              isShow: true,
              log: require('@/assets/menu/two_dict_grey_icon@2x.png'),
              log_two: require('@/assets/menu/two_dict_blue_icon@2x.png'),
              title: '数据字典',
              path: '/dict',
			  params: {}
            }
          ]
        }
      ],
    }
  },
  mounted() {
    this.initData();
  },
  created() {
    this.handleSelect(this.$route.path)
  },
  watch: {
    $route(route) {
      this.handleSelect(route.path)
    }
  },
  methods: {
    initData() {
      for (let i = 0; i < this.userInfo.menus_list.length; i++) {
        for (let j = 0; j < this.menuList.length; j++) {
          if (this.userInfo.menus_list[i].title == this.menuList[j].title) {
            this.menuList[j].isShow = this.userInfo.menus_list[i].is_show == '1' ? true : false;
            this.updateItem(this.userInfo.menus_list[i].children, this.menuList[j].child, j);
          }
        }
      }
      // this.menuIndex = this.menuList[0].child[0].path;
    },
    updateItem(dataList, itemList, index) {
      for (let i = 0; i < dataList.length; i++) {
        for (let j = 0; j < itemList.length; j++) {
          if (dataList[i].title == itemList[j].title) {
            this.menuList[index].child[j].isShow = dataList[i].is_show == '1' ? true : false;
          }
        }
      }
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
      // this.menuIndex = keyPath[1];
      this.$store.commit('SET_ROUTERPATH', key);
      this.$nextTick(() => {
        this.menuIndex = this.$store.state.reouterPath;
      })
    },

  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }

}
</script>

<style lang="scss" scoped>
.menu-bar-main {
  .log-img {
    width: 200px;
    height: 60px;
  }

  .el-menu-vertical-demo {
    .btn-img {
      width: 18px;
      height: 18px;
      margin-right: 11px;
    }

    .title-text {
      font-weight: bold;
    }
  }

}


</style>
import Cookies from "js-cookie"

export function getToken(){
	return localStorage.getItem('token');
	// return Cookies.get('token');
}

export function setToken(token) {
	localStorage.setItem('token', token);
	// return Cookies.set('token', token);
}

export function getQuestionList(){
	return localStorage.getItem('question_list')
}

export function setQuestionList(data){
	return localStorage.setItem('question_list',data)
}

export function removeToken() {
	localStorage.removeItem('token');
	localStorage.removeItem('userInfo');
	localStorage.clear();
  // return Cookies.remove('token');
}

export function setUserInfo(data) {
	localStorage.setItem('userInfo', JSON.stringify(data));
}

export function getUserInfo() {
	let userInfo = localStorage.getItem('userInfo');
	return userInfo?JSON.parse(userInfo):null;
	// return userInfo;
}



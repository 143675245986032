// 导入axios实例
import httpRequest from '@/request/index'

// 获取字典类型列表
export function dictList(param) {
    return httpRequest({
        url: 'admin/system/dictionary_type_list',
        method: 'GET',
        data: param,
    })
}
// 根据id获取字典类型信息
export function getDict(id) {
    return httpRequest({
        url: 'admin/system/dictionary_type_info/' + id,
        method: 'GET'
    })
}
// 根据id删除数据字典类型
export function delDict(param) {
    return httpRequest({
        url: 'admin/system/dictionary_type_del',
        method: 'delete',
        data: param,
    })
}

// 根据字典id获取字典项列表
export function dictDetailList(param) {
    return httpRequest({
        url: 'admin/system/dictionary_list',
        method: 'GET',
        data: param,
    })
}

// 根据id删除数据字典项
export function delDetailDict(id) {
    return httpRequest({
        url: 'admin/system/dictionary_del/' + id,
        method: 'delete'
    })
}

// 编辑字典项信息
export function editDetailDict(param) {
    return httpRequest({
        url: 'admin/system/dictionary_save',
        method: 'POST',
        data: param
    })
}

// 根据id获取字典项信息
export function getDetailDict(id) {
    return httpRequest({
        url: 'admin/system/dictionary_info/' + id,
        method: 'GET'
    })
}


// 评语指标列表
export function commentTargetList(data) {
    return httpRequest({
        url: 'admin/system/comment_target_list',
        method: 'GET'
    })
}

// 评语设置列表
export function commentSetList(param) {
    return httpRequest({
        url: 'admin/system/comment_set_list',
        method: 'GET',
		params: param
    })
}

// 评语设置
export function commentSet(param) {
    return httpRequest({
        url: 'admin/system/comment_set',
        method: 'POST',
		data: param
    })
}


// 导入axios实例
import httpRequest from '@/request/index'

/***************************** 登录所有接口 **************************************/




// 登录
export function getLogin(param) {
    return httpRequest({
		url: 'admin/login',
		method: 'POST',
		data: param,
	})
}
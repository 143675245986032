// 导入axios实例
import httpRequest from '@/request/index'

// 获取角色列表
export function roleList(param) {
    return httpRequest({
        url: 'admin/system/role_list',
        method: 'GET',
        data: param,
    })
}

// 根据id获取角色信息
export function getRole(id) {
    return httpRequest({
        url: 'admin/system/role_info/' + id,
        method: 'GET'
    })
}

// 根据id删除数据角色
export function delRole(id) {
    return httpRequest({
        url: 'admin/system/role_del',
        method: 'delete',
        data: {id: id}
    })
}

// 修改角色信息
export function editRole(param) {
    return httpRequest({
        url: 'admin/system/role_save',
        method: 'post',
        data: param,
    })
}


// 根据id修改角色状态
export function editRoleStatus(param) {
    return httpRequest({
        url: 'admin/system/role_status',
        method: 'post',
        data: param,
    })
}

//  权限菜单树列表
export function getRoleTreeList(type) {
    return httpRequest({
        url: 'admin/system/rule_tree_list/' + type,
        method: 'get'
    })
}

//  权限选项列表
export function getRoleOption() {
    return httpRequest({
        url: 'admin/system/role_option_list',
        method: 'get'
    })
}

/***************************** 问卷调查所有接口 **************************************/


// 导入axios实例
import httpRequest from '@/request/index'



// 获取问卷调查列表
export function questionnaire_list(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_list',
		method: 'GET',
		params: param,
	})
}

// 获取问卷调查目录列表
export function questionnaire_dir_list(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_dir_list',
		method: 'GET',
		params: param
	})
}


// 企业基础信息
export function questionnaire_basic_info(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_basic_info',
		method: 'GET',
		params: param
	})
}

// 问卷目录删除
export function questionnaire_del_dir(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_del_dir/'+param,
		method: 'PUT',
		// params: param
	})
}

// 目录详情
export function questionnaire_dir_info(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_dir_info',
		method: 'GET',
		params: param
	})
}

// 添加/编辑问卷调查
export function questionnaire_save(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_save',
		method: 'POST',
		data: param
	})
}

// 问卷目录添加/编辑
export function questionnaire_save_dir(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_save_dir',
		method: 'POST',
		data: param
	})
}

// 移动目录
export function questionnaire_dir_move(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_dir_move',
		method: 'POST',
		params: param
	})
}

// 问卷目录--企业基础信息详情
export function questionnaire_basic_info_detail(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_basic_info_detail',
		method: 'GET',
		params: param
	})
}

// -问卷删除
export function questionnaire_del(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_del/'+param,
		method: 'PUT',
		data: param
	})
}

// -预览列表
export function questionnaire_number_info(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_number_info',
		method: 'GET',
		// data: param,
		params: param
	})
}

// -问卷详情
export function questionnaire_detail(param) {
    return httpRequest({
		url: 'admin/question/questionnaire_detail',
		method: 'GET',
		// data: param,
		params: param
	})
}
//预设题库
export function questionnaire_basic_bank(param) {
   return httpRequest({
      url: 'admin/question/questionnaire_basic_bank',
      method: 'GET',
      params: param,
   })
}

//问卷列表
export function quest_list(param){
   return httpRequest({
      url:'admin/naire/lists',
      method:'post',
      param:param,
      data:param
   })
}


//上传名单
export function upload_file(param){
   return httpRequest({
      url:'admin/naire/uploadfile',
      method:'post',
      data:param,
      type: 'file'
   })
}


//导出名单
export function bulk_download(param){
   return httpRequest({
      url:'admin/naire/downexcel',
      method:'post',
      data:param
   })
}

//保存名单
export function save_list(param){
   return httpRequest({
      url:'admin/naire/save',
      method:'post',
      param:param,
      data:param
   })
}

//发送短信
export function send_message(param){
   return httpRequest({
      url:'admin/naire/sendmobile',
      method:'post',
      param:param,
      data:param
   })
}

//添加题目和选项
export function questionAdd(param){
   return httpRequest({
      url:'admin/question/question_add',
      method:'post',
      param:param,
      data:param
   })
}

//编辑题目和选项
export function questionEdit(param){
   return httpRequest({
      url:'admin/question/question_edit',
      method:'post',
      data:param
   })
}


//删除题目及选项
export function questionDel(param){
   return httpRequest({
      url:'admin/question/question_del/'+param,
      method:'post',
   })
}

//移动题目
export function questionMove(param){
   return httpRequest({
      url:'admin/question/question_move',
      method:'post',
      data:param
   })
}

//答题地址
export function naire_getqueinfo(param) {
    return httpRequest({
        url: 'admin/naire/getqueinfo',
        method: 'POST',
        params: param
    })
}


/*批量下载答卷*/
export function contact_list(param) {
    return httpRequest({
        url: 'admin/export/contact_list',
        method: 'POST',
        params: param
    })
}

/*答卷数据列表*/
export function datalist(param) {
    return httpRequest({
        url: 'admin/statistics/datalist',
        method: 'POST',
        params: param
    })
}

/*查询问卷详情*/
export function statistics_show(param) {
    return httpRequest({
        url: 'admin/statistics/show',
        method: 'POST',
        params: param
    })
}

/*查询问卷详情*/
export function company_options() {
    return httpRequest({
        url: 'admin/company/company_info',
        method: 'GET'
    })
}

